<template>
    <div class="index-page">
        <!-- 至底背景层 -->
        <video
            v-show="isShowVideoLayer"
            class="background-layer"
            ref="BGVideo"
            preload="auto"
            :controls="false"
            :muted="true"
            :loop="true"
            x5-video-player-type="h5"
            x5-playsinline="true"
            :playsInline="true"
            :webkit-playsinline="true"
        >
            <source
                src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-3a1ff277-b523-4a94-adde-1471f817c29d/dada202f-fac9-4c04-8378-5f9e06bb0017.mp4"
                type="video/mp4"
            />
        </video>

        <!-- 顶部导航栏 -->
        <div class="navigation-bar">
            <div class="navigation-bar-c">
                <div class="navigation-logo" v-show="!isMobile">
                    <img
                        src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-3a1ff277-b523-4a94-adde-1471f817c29d/b377def7-3787-4798-afc4-6a3cc265b6aa.png"
                    />
                </div>
                <div
                    class="navigation-item"
                    :class="{ active: inNav == item.value }"
                    v-for="item in navs"
                    :key="item.value"
                    @click="changeNav(item)"
                >
                    {{ item.label }}
                </div>
            </div>
        </div>

        <swiper
            class="page-swiper"
            ref="pageSwiper"
            :options="pageSwiper"
            @slideChange="changePage"
        >
            <swiper-slide class="page-swiper-item">
                <div class="index-background">
                    <img
                        src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-aliyun-1drkl6bpt7rp8b95ea/7b3ef130-643c-11eb-8ff1-d5dcf8779628.jpg"
                    />
                </div>

                <div class="index-container">
                    <p
                        class="ani content-text"
                        swiper-animate-effect="fadeInUp"
                        swiper-animate-delay=".3s"
                        swiper-animate-duration=".5s"
                    >
                        在这个喧嚣的城市中
                    </p>
                    <p
                        class="ani content-text"
                        swiper-animate-effect="fadeInUp"
                        swiper-animate-delay="1.3s"
                        swiper-animate-duration=".5s"
                    >
                        你是否也在寻找那片属于你自己的宁静
                    </p>
                    <p
                        class="ani content-text"
                        swiper-animate-effect="fadeInUp"
                        swiper-animate-delay="2.3s"
                        swiper-animate-duration=".5s"
                    >
                        给生活一个新起点
                    </p>
                    <p
                        class="ani content-text"
                        swiper-animate-effect="fadeInUp"
                        swiper-animate-delay="3.3s"
                        swiper-animate-duration=".5s"
                    >
                        慢下来，发现身边的美好点滴
                    </p>
                </div>
            </swiper-slide>
            <swiper-slide class="page-swiper-item">
                <div
                    class="ani project-container wrapper-container"
                    swiper-animate-effect="fadeInUp"
                    swiper-animate-delay=".3s"
                    swiper-animate-duration=".5s"
                >
                    <!-- PC端项目展览 -->
                    <swiper
                        class="project-swiper project-swiper--pc"
                        ref="projectSwiper"
                        :options="projectSwiper"
                        v-show="!isMobile"
                    >
                        <swiper-slide
                            class="project-swiper-item"
                            v-for="(item, index) in projects"
                            :key="index"
                        >
                            <el-card class="project-card" shadow="hover">
                                <div slot="header" class="project-header">
                                    <div class="project-header-wrap">
                                        <div class="project-title">
                                            {{ item.title }}
                                        </div>
                                        <div class="project-tags">
                                            <el-tag
                                                class="market-item"
                                                v-for="(
                                                    market, tindex
                                                ) in item.markets"
                                                :key="tindex"
                                                :type="market.type"
                                                effect="dark"
                                                size="mini"
                                                @click="
                                                    openProject(
                                                        market,
                                                        item.title
                                                    )
                                                "
                                                >{{ market.text }}</el-tag
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div class="project-bodyer">
                                    <img
                                        class="project-image"
                                        fit="fill"
                                        :src="item.src"
                                    />
                                    <div class="project-mask">
                                        <img
                                            class="
                                                project-mask__image
                                                project-mask__panel
                                            "
                                            fit="fill"
                                            :src="item.mpcode"
                                            v-if="item.mpcode"
                                        />
                                        <div
                                            class="
                                                project-mask__label
                                                project-mask__panel
                                            "
                                            v-else
                                        >
                                            <p>点击上方H5预览抢先体验</p>
                                            <p>微信小程序版暂未发布</p>
                                        </div>
                                    </div>
                                </div>
                            </el-card>
                        </swiper-slide>

                        <div
                            class="swiper-button-prev project-button-prev"
                            slot="button-prev"
                        ></div>
                        <div
                            class="swiper-button-next project-button-next"
                            slot="button-next"
                        ></div>
                    </swiper>

                    <!-- 移动端项目展览 -->
                    <swiper
                        class="project-swiper project-swiper--mobile"
                        ref="mobileProjectSwiper"
                        :options="mobileProjectSwiper"
                        v-show="isMobile"
                    >
                        <swiper-slide
                            class="project-swiper-item"
                            v-for="(item, index) in projects"
                            :key="index"
                        >
                            <el-card class="project-card" shadow="hover">
                                <div slot="header" class="project-header">
                                    <div class="project-title">
                                        {{ item.title }}
                                    </div>
                                    <div class="project-tags">
                                        <el-tag
                                            v-for="(
                                                market, tindex
                                            ) in item.markets"
                                            :key="tindex"
                                            :type="market.type"
                                            effect="dark"
                                            size="mini"
                                            @click="
                                                openProject(market, item.title)
                                            "
                                            >{{ market.text }}</el-tag
                                        >
                                    </div>
                                </div>
                                <div class="project-bodyer">
                                    <img
                                        class="project-image"
                                        fit="fill"
                                        :src="item.src"
                                    />
                                    <div class="project-mask">
                                        <img
                                            class="
                                                project-mask__image
                                                project-mask__panel
                                            "
                                            fit="fill"
                                            :src="item.mpcode"
                                            v-if="item.mpcode"
                                        />
                                        <div
                                            class="
                                                project-mask__label
                                                project-mask__panel
                                            "
                                            v-else
                                        >
                                            <p>点击上方H5预览抢先体验</p>
                                            <p>微信小程序版暂未发布</p>
                                        </div>
                                    </div>
                                </div>
                            </el-card>
                        </swiper-slide>

                        <div
                            class="swiper-button-prev project-button-prev"
                            slot="button-prev"
                        ></div>
                        <div
                            class="swiper-button-next project-button-next"
                            slot="button-next"
                        ></div>
                    </swiper>
                </div>
            </swiper-slide>
            <swiper-slide class="page-swiper-item">
                <div
                    class="ani journal-container wrapper-container"
                    swiper-animate-effect="fadeInUp"
                    swiper-animate-delay=".3s"
                    swiper-animate-duration=".5s"
                >
                    <swiper
                        class="journal-swiper"
                        ref="journaalSwiper"
                        :options="journalSwiper"
                    >
                        <swiper-slide class="journal-swiper-item">
                            <el-timeline class="journal-timeline">
                                <el-timeline-item
                                    v-for="(item, index) in journals"
                                    :key="index"
                                    :timestamp="item.timestamp"
                                    placement="top"
                                >
                                    <div class="journal-card-title">
                                        {{ item.title }}
                                    </div>
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="openJournal(index)"
                                        >查看</el-button
                                    >
                                </el-timeline-item>
                            </el-timeline>
                        </swiper-slide>
                        <div
                            class="swiper-scrollbar journal-swiper-scrollbar"
                            slot="scrollbar"
                        ></div>
                    </swiper>
                </div>

                <div
                    class="ani copyright-bar"
                    swiper-animate-effect="fadeInUp"
                    swiper-animate-delay="1s"
                    swiper-animate-duration=".3s"
                >
                    <div class="copyright-bar-c">
                        <div class="copyright-item">457258052@qq.com</div>
                        <div class="copyright-item">Copyright 2021</div>
                        <div class="copyright-item">© All Rights Reserved</div>
                        <div class="copyright-item">
                            <a
                                class="text-link"
                                href="https://beian.miit.gov.cn"
                                target="_blank"
                                >闽ICP备19025939号-2</a
                            >
                        </div>
                    </div>
                </div>
            </swiper-slide>

            <div
                class="swiper-pagination page-swiper-pagination"
                slot="pagination"
            ></div>
        </swiper>

        <!-- 底部下滑指示 -->
        <div class="arrow-indicator" v-show="isShowArrowIndicator">
            <img
                src="@/assets/image/arrow_up_primary.png"
                v-show="inShowArrowPrimary"
            />
            <img
                src="@/assets/image/arrow_up.png"
                v-show="!inShowArrowPrimary"
            />
        </div>

        <!-- 查看日志 -->
        <el-dialog
            class="journal-dialog"
            :title="(journal && journal.title) || '日志'"
            :visible.sync="isShowJournal"
            @close="closeJournal"
        >
            <div
                class="journal-dialog-markdown markdown-body"
                v-if="journal && journal.file"
            >
                <vue-markdown
                    :source="require(`@/assets/docs/${journal.file}`)"
                    v-highlight
                ></vue-markdown>
            </div>
            <div slot="footer" class="journal-dialog-footer">
                <el-button
                    type="primary"
                    size="small"
                    plain
                    @click="isShowJournal = false"
                    >关 闭</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Api from "@/api/reqmock/index.js";
import { mapState } from "vuex";
import "swiper/dist/css/swiper.css";
import "@/common/lib/swiper/swiper.animate.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import * as swiperAni from "@/common/lib/swiper/swiper.animate.js";
import VueMarkdown from "vue-markdown";
import "github-markdown-css";
import "highlight.js/styles/github.css";
import hljs from "highlight.js";

export default {
    name: "Index",
    components: {
        swiper,
        swiperSlide,
        VueMarkdown,
    },
    directives: {
        highlight: {
            bind(el) {
                const blocks = el.querySelectorAll("pre code");
                blocks.forEach((item) => {
                    hljs.highlightBlock(item);
                });
            },
        },
    },
    data() {
        return {
            inNav: 0,
            navs: [
                {
                    label: "首页",
                    value: 0,
                },
                {
                    label: "作品",
                    value: 1,
                },
                {
                    label: "日志",
                    value: 2,
                },
            ],
            isShowVideoLayer: false,
            isShowArrowIndicator: true,
            inShowArrowPrimary: true,
            projects: null,
            journals: null,
            isShowJournal: false,
            journal: {
                title: "",
                content: "",
                html: "",
            },
            pageSwiper: {
                direction: "vertical", // 滑动方向
                autoplay: false, // 自调播放
                mousewheel: true, // 鼠标触发滑动事件
                pagination: {
                    el: ".page-swiper-pagination",
                    type: "bullets",
                    clickable: true,
                },
                noSwiping: true, // 可以在slide上增加类名'swiper-no-swiping'，使该slide无法拖动
                allowSlidePrev: false, // 第一个元素不可下拉
                allowSlideNext: true, // 最后一个元素不可上拉
                observer: true,
                observeParents: true,
                on: {
                    init() {
                        swiperAni.swiperAnimateCache(this); // 隐藏动画元素
                        this.emit("slideChangeTransitionEnd"); // 派遣动画元素
                    },
                    slideChangeTransitionEnd() {
                        swiperAni.swiperAnimate(this); // 每次slide滑动结束后都运行动画，如果动画只想执行一次需要将ani类移除
                        // this.slides.eq(this.activeIndex).find('.ani').removeClass('ani');
                    },
                },
            },
            journalSwiper: {
                direction: "vertical",
                slidesPerView: "auto",
                freeMode: true,
                scrollbar: {
                    el: ".journal-swiper-scrollbar",
                    draggable: true,
                },
                mousewheel: true,
                observer: true,
                observeParents: true,
            },
            projectSwiper: {
                slidesPerView: 3,
                slidesPerGroup: 3,
                navigation: {
                    prevEl: ".project-button-prev",
                    nextEl: ".project-button-next",
                },
                observer: true,
                observeParents: true,
            },
            mobileProjectSwiper: {
                slidesPerView: 1,
                slidesPerGroup: 1,
                navigation: {
                    prevEl: ".project-button-prev",
                    nextEl: ".project-button-next",
                },
                observer: true,
                observeParents: true,
            },
        };
    },
    mounted() {
        this.initData();
    },
    methods: {
        initData() {
            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
            });
            Promise.all([Api.getProjects(), Api.getDocs()])
                .then((res) => {
                    this.projects = res[0].data;
                    this.journals = res[1].data;
                    loading.close();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        changeNav(item) {
            this.$refs.pageSwiper.swiper.slideTo(item.value);
        },
        changePage() {
            // 实时获取导航当前选中项
            this.inNav = this.$refs.pageSwiper.swiper.activeIndex;

            // 当选中项为第一个元素时阻止下拉
            if (this.$refs.pageSwiper.swiper.activeIndex) {
                this.$refs.pageSwiper.swiper.allowSlidePrev = true;
            } else {
                this.$refs.pageSwiper.swiper.allowSlidePrev = false;
            }

            // 箭头指向元素是否隐藏
            if (
                this.$refs.pageSwiper.swiper.activeIndex ==
                this.navs.length - 1
            ) {
                this.$refs.pageSwiper.swiper.allowSlideNext = false;
                this.isShowArrowIndicator = false;
            } else {
                this.$refs.pageSwiper.swiper.allowSlideNext = true;
                this.isShowArrowIndicator = true;
            }

            // 箭头指示的深浅色切换
            if (this.$refs.pageSwiper.swiper.activeIndex) {
                this.inShowArrowPrimary = true;
            } else {
                this.inShowArrowPrimary = false;
            }

            // 视频元素是否隐藏（预加载）
            if (
                this.$refs.pageSwiper.swiper.activeIndex >=
                this.navs.length - 2
            ) {
                this.isShowVideoLayer = true;

                // 如何视频处在暂停状态则进行播放
                if (this.$refs.BGVideo.paused) {
                    this.$refs.BGVideo.play();
                }
            } else {
                this.isShowVideoLayer = false;
            }
        },
        openJournal(index) {
            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.5)",
            });
            this.journal = this.journals[index];
            let timer = setTimeout(() => {
                loading.close();
                this.isShowJournal = true;
                clearTimeout(timer);
            }, 300);
        },
        closeJournal() {
            let timer = setTimeout(() => {
                this.journal = null;
                clearTimeout(timer);
            }, 300);
        },
        openProject(item, title) {
            if (item.name == "H5") {
                window.open(item.url, title);
            } else if (item.name == "Android") {
                let downloadEle = document.createElement("a");
                downloadEle.download = ""; // 为空则使用原文件名
                downloadEle.href = item.url;
                document.body.appendChild(downloadEle);
                downloadEle.click();
                downloadEle.remove();
            }
        },
    },
    computed: {
        ...mapState("global", ["isMobile"]),
    },
};
</script>

<style src="../assets/style/index.css" scoped></style>