import Mock from 'mockjs'
const table = {
    getProjects() {
        return Mock.mock({
            "code": 200,
            "message": "request:ok",
            "data": [
                {
                    title: "Forum - 活力贴",
                    src: "https://vkceyugu.cdn.bspapp.com/VKCEYUGU-aliyun-1drkl6bpt7rp8b95ea/1b6c5800-643d-11eb-8d54-21c4ca4ce5d7.png",
                    mpcode: "",
                    markets: [
                        {
                            text: "H5预览",
                            name: "H5",
                            type: "success",
                            url: "http://forum.webyi.top"
                        },
                        {
                            text: "Android下载",
                            name: "Android",
                            type: "info",
                            url: "https://vkceyugu.cdn.bspapp.com/VKCEYUGU-aliyun-1drkl6bpt7rp8b95ea/1f5cd6a0-643e-11eb-8ff1-d5dcf8779628.apk"
                        }
                    ]
                },
                {
                    title: "AliveUI - 活力组件库",
                    src: "https://vkceyugu.cdn.bspapp.com/VKCEYUGU-aliyun-1drkl6bpt7rp8b95ea/1a855360-643d-11eb-8d54-21c4ca4ce5d7.png",
                    mpcode: "https://vkceyugu.cdn.bspapp.com/VKCEYUGU-aliyun-1drkl6bpt7rp8b95ea/39636510-643d-11eb-a16f-5b3e54966275.jpg",
                    markets: [
                        {
                            text: "H5预览",
                            name: "H5",
                            type: "success",
                            url: "http://alive.webyi.top"
                        },
                        {
                            text: "Android下载",
                            name: "Android",
                            type: "info",
                            url: "https://vkceyugu.cdn.bspapp.com/VKCEYUGU-aliyun-1drkl6bpt7rp8b95ea/1eb59a20-643e-11eb-b997-9918a5dda011.apk"
                        }
                    ]
                },
                {
                    title: "Mall - 活力商城",
                    src: "https://vkceyugu.cdn.bspapp.com/VKCEYUGU-aliyun-1drkl6bpt7rp8b95ea/1c1b5cb0-643d-11eb-b680-7980c8a877b8.png",
                    mpcode: "",
                    markets: [
                        {
                            text: "H5预览",
                            name: "H5",
                            type: "success",
                            url: "http://mall.webyi.top"
                        }
                    ]
                },
                {
                    title: "Video - 活力短视频",
                    src: "https://vkceyugu.cdn.bspapp.com/VKCEYUGU-aliyun-1drkl6bpt7rp8b95ea/19cadd00-643d-11eb-b997-9918a5dda011.png",
                    mpcode: "",
                    markets: [
                        {
                            text: "H5预览",
                            name: "H5",
                            type: "success",
                            url: "http://video.webyi.top"
                        }
                    ]
                }
            ]
        });
    },
    getJournals() {
        return Mock.mock({
            "code": 200,
            "message": "request:ok",
            "data": [
                {
                    author: "陈义",
                    timestamp: "2020/3/11 13:13",
                    title: "最美的旅行，遇见更好的自己",
                    html: `<p style="font-family:Arial, SimSun;font-size:14px;">
                &emsp;&emsp;走走停停，却始终在途中，瞥瞥看看，却始终在眼里，朦朦胧胧，却始终在心上。这就是最美的旅行，遇见更好的自己。
            </p>
            <p style="font-family:Arial, SimSun;font-size:14px;margin-top:10px;">
                &emsp;&emsp;大地上的异乡者踩着异乡的路，除却对家的私念也就全寄托在遇见一座座美丽的、值得托付的城。
            </p>
            <p style="font-family:Arial, SimSun;font-size:14px;margin-top:10px;">
                &emsp;&emsp;一颗不安的心其实就足以填满一个旅行者，当安适、规则化的生活久了，总有人会不同于那些循规蹈矩的人，一座城哪能牢牢的困住一个人的心一辈子，如是，随心上路，寻找美景，也寻找最舒服的自己。
            </p>
            <p style="font-family:Arial, SimSun;font-size:14px;margin-top:10px;">
                &emsp;&emsp;有人说，一个目的地，一份追逐的心就能满足一次旅行。多多少少还是可取的，比那些有心无目的或有目的却无心的梦行者强很多。那些在旅行前考虑吃住、花销的在我眼里真的不适合做一个要有旅途经历的游走者，计划性太强了，放松不下来心的都不叫旅行，那勉强叫出行，只是离开一个地方去向另一个地方，而且走的一直是身体，心还停在那个平静的老地方。
            </p>
            <p style="font-family:Arial, SimSun;font-size:14px;margin-top:10px;">
                &emsp;&emsp;旅行，必不可少的是一些惊喜。走一条悠远的幽静小路到头来发现前方无路可走而绕回去，迈出一只以为落在杂草堆上的脚却发现脚下是潺潺细流，坐上一辆车走下来却发现自己迷了路，这最简单的玩笑才像是在一个陌生地方的放松之旅。
            </p>
            <p style="font-family:Arial, SimSun;font-size:14px;margin-top:10px;">
                &emsp;&emsp;其实最好的旅游是一无所知的旅行。没有目的地，才能更在乎沿途的风景，没有追逐的心，才能竟享一切得以舒心。一座小土丘可以当成一座山，一个小水坑可以当成一潭湖，走进一片草地就如走进一个花园，看见一个游者就当遇见知音。只是随着脚步，随着心声，漫无目的，毫不在乎，轻松多了，每秒多了，也就能遇见更好的自己。
            </p>`
                },
                {
                    author: "陈义",
                    timestamp: "2020/3/11 12:12",
                    title: "H5中的定时器与requestAnimationFrame有什么区别",
                    html: `<p>定时器：通过设定间隔时间来不断改变图像位置达到动画效果，但容易出现卡顿抖动的现象；</p><p>requestAnimationFrame：由系统决定回调函数的执行时机。60Hz的刷新频率，那么每次刷新的间隔中会执行一次回调函数，不会引起丢帧，不会卡顿。</p>`
                },
                {
                    author: "陈义",
                    timestamp: "2020/3/11 11:11",
                    title: "Vue中computed与methods有什么区别",
                    html: `<p>computed只会执行属性值中产生改变了的那些方法；</p><p>而methods中只要有属性值改变所有的方法都会被执行，导致性能消耗很大。</p>`
                },
                {
                    author: "陈义",
                    timestamp: "2020/3/10 23:23",
                    title: "Vue中子组件怎么与父组件通讯",
                    html: `<p>通过自定义事件传递给父组件：this.$emit('eventName',this.value)</p>`
                },
                {
                    author: "陈义",
                    timestamp: "2020/3/10 22:22",
                    title: "Vue中当前页面开启了作用域scoped，怎么才能控制子组件的样式",
                    html: `<p>通过深度选择器控制：.a >>> .b{}</p>`
                },
                {
                    author: "陈义",
                    timestamp: "2020/3/10 21:21",
                    title: "H5中video标签怎么在不全屏的情况下铺满屏幕",
                    html: `<p>给video标签设置样式：.video{width:100%;height:100%;object-Fit:fill;}</p>`
                },
                {
                    author: "陈义",
                    timestamp: "2020/3/10 20:20",
                    title: "Javascript中this的指向",
                    html: `<p>普通函数：this指向调用它的对象；</p><p>箭头函数：this指向上层函数调用它的对象，若上层还是箭头函数则往上找，直至window对象；</p><p>特例：自调函数和定时器函数this指向window，可通过箭头函数写法，也让它的this指向调用它的对象</p>`
                }
            ]
        });
    },
    getAlbums() {
        return Mock.mock({
            "code": 200,
            "message": "request:ok",
            "data": [
                "https://vkceyugu.cdn.bspapp.com/VKCEYUGU-aliyun-1drkl6bpt7rp8b95ea/116b60f0-643b-11eb-8ff1-d5dcf8779628.jpg",
                "https://vkceyugu.cdn.bspapp.com/VKCEYUGU-aliyun-1drkl6bpt7rp8b95ea/121d24c0-643b-11eb-8d54-21c4ca4ce5d7.jpg",
                "https://vkceyugu.cdn.bspapp.com/VKCEYUGU-aliyun-1drkl6bpt7rp8b95ea/12c63600-643b-11eb-a16f-5b3e54966275.jpg",
                "https://vkceyugu.cdn.bspapp.com/VKCEYUGU-aliyun-1drkl6bpt7rp8b95ea/139c4ab0-643b-11eb-b997-9918a5dda011.jpg",
                "https://vkceyugu.cdn.bspapp.com/VKCEYUGU-aliyun-1drkl6bpt7rp8b95ea/143d45a0-643b-11eb-a16f-5b3e54966275.jpg",
                "https://vkceyugu.cdn.bspapp.com/VKCEYUGU-aliyun-1drkl6bpt7rp8b95ea/15130c30-643b-11eb-8ff1-d5dcf8779628.jpg",
                "https://vkceyugu.cdn.bspapp.com/VKCEYUGU-aliyun-1drkl6bpt7rp8b95ea/15cbd4e0-643b-11eb-8d54-21c4ca4ce5d7.jpg",
                "https://vkceyugu.cdn.bspapp.com/VKCEYUGU-aliyun-1drkl6bpt7rp8b95ea/16869960-643b-11eb-a16f-5b3e54966275.jpg",
                "https://vkceyugu.cdn.bspapp.com/VKCEYUGU-aliyun-1drkl6bpt7rp8b95ea/172e7220-643b-11eb-8ff1-d5dcf8779628.jpg",
                "https://vkceyugu.cdn.bspapp.com/VKCEYUGU-aliyun-1drkl6bpt7rp8b95ea/17e56610-643b-11eb-8d54-21c4ca4ce5d7.jpg"
            ]
        });
    },
    getDocs() {
        return Mock.mock({
            "code": 200,
            "message": "request:ok",
            "data": [
                {
                    author: "陈义",
                    title: "命名规范",
                    file: "name.md",
                    timestamp: "2021/9/10 20:20"
                },
                {
                    author: "陈义",
                    title: "微信小程序",
                    file: "wechat.md",
                    timestamp: "2021/9/9 20:20"
                },
                {
                    author: "陈义",
                    title: "支付宝小程序",
                    file: "alipay.md",
                    timestamp: "2021/9/8 20:20"
                }
            ]
        })
    }
}
export default table;