import Table from './table'
const Request = (options) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            if (options.callback) {
                resolve(options.callback());
            } else {
                reject('request is not defined');
            }
        }, 1000)
    })
}

const Api = {
    getProjects() {
        return Request({ callback: Table.getProjects });
    },
    getJournals() {
        return Request({ callback: Table.getJournals });
    },
    getAlbums() {
        return Request({ callback: Table.getAlbums });
    },
    getDocs() {
        return Request({ callback: Table.getDocs });
    },
}
export default Api;